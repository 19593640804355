<template>
  <div><br /></div>
</template>
<script>
export default {
  mounted() {
    var ua = window.navigator.userAgent;
    var isIOS = !ua.includes("Android");
    function getCode() {
      var captcha1 = new TencentCaptcha("2053606225", callback);
      captcha1.show(); // 显示验证码
    }
    // getCode();
    let _that = this
    function callback(res) {
      /* res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
      res（客户端出现异常错误 仍返回可用票据） = {ret: 0, ticket: "String", randstr: "String", errorCode: Number, errorMessage: "String"}
      res（用户主动关闭验证码）= {ret: 2}
    */
      if (res.ret === 0) {
        // 获取票据、随机数并调用App端注入的方法传入票据、随机数，进行后台票据校验
        // var result = { randstr: res.randstr, ticket: res.ticket };
          _that.$emit('captchas',res)
        if (isIOS) {
          jsToOcFunction(res);
        } else {
          window.jsBridge.getData(res.ticket, res.randstr);
        }
       
      } else {
        if (isIOS) {
          window.webkit.messageHandlers.jsToOcNoPrams.postMessage({ type: 0 });
        } else {
          window.jsBridge.close();
        }
        // getCode()
      }
    }
    // 验证码js加载错误处理
    function loadErrorCallback() {
      var CaptchaAppId = "";
      /* 生成票据或自行做其它处理 */
      var ticket =
        "terror_1001_" + CaptchaAppId + Math.floor(new Date().getTime() / 1000);
      callback({
        ret: 0,
        randstr: "@" + Math.random().toString(36).substr(2),
        ticket: ticket,
        errorCode: 1001,
        errorMessage: "jsload_error",
      });
    }
    function jsToOcFunction(res) {
      window.webkit.messageHandlers.jsToOcWithPrams.postMessage({
        randstr: res.randstr,
        ticket: res.ticket,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.tcaptcha-transform {
  /* width: 90% !important;
    height: 90% !important;
    left: 50% !important;
    top: 50% !important; */
  transform: scale(2);
  border: none !important;
}
.t-mask {
}
/* #tcaptcha_iframe{
    width: 100% !important;
    height: 100% !important;
  } */
</style>