
  import { defineComponent,ref,reactive,toRefs,getCurrentInstance,nextTick,onMounted} from 'vue'
  import {NavBar,Field, CellGroup,Icon, Checkbox,Overlay,Cell, Toast} from 'vant'
  import { useRoute,useRouter } from 'vue-router'
  import api from '@/assets/js/api'
  import captcha from '../captcha/mobile.vue'
  import storage from '@/assets/js/localStorage'
  const    CountryCode = require('@/assets/js/CountryCode.json')
  export default defineComponent({
    name: 'login',
    components: {
        [NavBar.name]: NavBar,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup,
         [Cell.name]: Cell,
        [Icon.name]: Icon,
        [Checkbox.name]: Checkbox,
        [Overlay.name]: Overlay ,
         captcha
    },
    setup: () => {
        const route:any = useRoute()
        const router = useRouter()
        const {proxy:_this} = getCurrentInstance()
        const areaCodeEl=ref(null)
        const countryList = {
            commonArea: [
                {
                  countryName: '中国',
                  phoneCode: '86'  
                },
                {
                  countryName: '中国香港',
                  phoneCode: '852'  
                },
                {
                  countryName: '中国台湾',
                  phoneCode: '886'  
                },
                {
                  countryName: '中国澳门',
                  phoneCode: '853'  
                },
            ],
            list:  CountryCode.data
        }
        let state = reactive({
            color: '',
            text: '注册成功!',
            isShow:false,
            value: null,
            time: null,
            checked: false,
            isShowCaptcha: false,
            showSelectArea: false,
        }) 
       
   
        const showArea= ()=>{
            state.showSelectArea = true
            nextTick(()=>{
            areaCodeEl.value.addEventListener(
                "touchmove",
                function(event) {
                    event.stopPropagation()
                },
                false);
            })
        }
        //  登录相关
        const loginInfo:any = reactive({
            areaCode: 86,
            phone: '',
            verifyCode: '',
            res:{
                ret: null,
                randstr: '',
                ticket: ''
            }
        })
        // 发送点击
        const countdown =ref(null)
        const isReSend = ref(false)
        let codeInterval = null
         const getCode= (_this as any).debounce(()=>{
            if(!loginInfo.phone.length){ 
                Toast('请输入手机号') 
                return
                }
            let  reg = /^1[3-9]\d{9}$/
        //    验证国内手机号
           if(loginInfo.areaCode == '86' && !reg.test(loginInfo.phone)){
                Toast('手机号格式有误!') 
                return
           }
            if(loginInfo.res.ret === 0){
             
                sendeCodeHandle()
               
            } else {
                state.isShowCaptcha = true
            }
           
         }, 500)
        
          const handleCaptcha = (data)=>{
            loginInfo.res = data
            getCode()
        }
     
        // 发送验证码
        const sendeCodeHandle = async ()=>{
            try {
           let res =  await api.sendCodeApi({phone: `+ ${loginInfo.areaCode} ${loginInfo.phone}`,rand_str: loginInfo.res.randstr,ticket: loginInfo.res.ticket})
           if(res.data.expireTime){
                countdown.value = 60
                loginInfo.res = {
                    ret: null,
                    randstr: '',
                    ticket: ''
                }
                if(codeInterval){
                    clearInterval(codeInterval)
                    codeInterval = null
                } 
                codeInterval = setInterval(() => {
                    countdown.value -= 1;
                    if (countdown.value <= 0) {
                    clearInterval(codeInterval);
                    countdown.value = 0
                    isReSend.value = true
                    codeInterval = null
                    }
                }, 1000);
           }
            } catch(err) {
              if(err.length) Toast(err)
            }  
        }
        // 登录
         const loginHandle = async ()=>{
            if(!state.checked) {
                Toast('请勾先选底部协议') 
                return
            }
            if(!loginInfo.phone || !loginInfo.verifyCode){
                  Toast(`请输入${loginInfo.phone ? '验证码' : '手机号'}`) 
                  return
            }
            try {
                const res:any = await api.loginInApi({type:'2',phone: `+ ${loginInfo.areaCode} ${loginInfo.phone}`,verifyCode: loginInfo.verifyCode})        
                // const res = {
                //     data:{
                //         token: 'e86c9c57f8505cc0c05178398d3e9c008b8b8260ed206c0502b09a80aa18b164',
                //         userInfo: {
                //             create_time: 1635237580,
                //             email: "5****@moe",
                //             email_validated: 0,
                //             head_url: "//xdream-test.oss-cn-shanghai.aliyuncs.com/avatar/936198?1635492869",
                //             idcard_verify_count: 0,
                //             idcard_verify_time: 0,
                //             isPass: true,
                //             level: 0,
                //             login_phone: "**********7573",
                //             name: "Sexy丶",
                //             phone: "",
                //             phone_validated: 1,
                //             update_time: 1654066363,
                //             user_id: 936198,
                //             user_idcard: null,
                //             user_info: null
                //         }
                //     }
                // }
                 state.color = '#3EBED3'
                state.isShow = true
                state.text = '登录成功'
                setTimeout(() => {
                state.isShow = false  
                }, 2000);
                storage.setUserInfo(res.data.userInfo)
                storage.setLoginToken(res.data.token)
                router.push(route.query.from)

            } catch(err){
                console.log(err)
                state.color = '#F14F46'
                state.isShow = true
                state.text = '验证码或用户名错误'
                //  msgNotice = {
                //     isShow:true,
                //     color: '#3EBED3',
                //     text: '登录成功'
                // }
                setTimeout(() => {
                state.isShow = false  
                }, 2000);
            }

           
        }
        const clickLeftHandle = ()=>{
            router.go(-1)
        }

        return {
            handleCaptcha,
            ...toRefs(state),
            loginHandle,
            getCode,
            countdown,
            isReSend,
            countryList,
            ...toRefs(loginInfo),
            clickLeftHandle,
            areaCodeEl,
            showArea
            
        }
    }
  })
